<template>
  <div>
    <!-- BUTTONS -->
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="save"
      :save_loading="save_loading"
      :btnDelete="true"
      :textDelete="$t('GENERAL.DELETE')"
      v-on:delete="isShowDialogDelete = true"
      :btnDuplicate="true"
      :duplicate_loading="duplicate_loading"
      :textDuplicate="$t('GENERAL.DUPLICATE')"
      v-on:duplicate="duplicate"
    />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="mb-2">
        <v-card-text>
          <v-row>
            <!-- TITLE -->
            <b-col lg="12" md="12" sm="12" cols="12" class="mb-5">
              <h5>
                <strong>
                  {{ $t("MARKETS.TITLE8") }}
                </strong>
                <br />
                <i>
                  {{ $t("MARKETS.TITLE9") }}
                </i>
              </h5>
            </b-col>

            <!-- NAME -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                :label="$t('MARKETS.NAME')"
                :rules="validations.required"
                outlined
                dense
              />
            </v-col>

            <!-- TABS -->
            <v-col cols="12" md="12">
              <CountrySelect :form-data.sync="formData" :data="editData" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card class="pt-3">
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="deleteItem">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CountrySelect from "@/view/content/components/CountrySelect";
import {
  UPDATE_MARKET,
  GET_MARKET,
  DELETE_MARKET,
  STORE_MARKET,
} from "@/core/services/store/market/market.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import lodash from "lodash";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: {
    CountrySelect,
    Buttons,
  },
  data() {
    return {
      save_loading: false,
      name: null,
      formData: null,
      isShowDialogDelete: false,
      duplicate_loading: false,
      //Validations
      validations: {
        valid: true,
        ...Rules,
      },
      editData: null,
    };
  },
  methods: {
    /**
     * return campus page
     * @method cancel
     */
    cancel() {
      this.$router.push("/configure/markets");
    },

    /**
     * Delete Market by id
     * @method deleteItem
     */
    deleteItem() {
      this.$store.dispatch(DELETE_MARKET, this.market.marketId);
      this.isShowDialogDelete = false;
      this.$router.push("/configure/markets");
    },

    /**
     * Create new Market
     * @method createNewMarket
     * @returns 'createNewMarket' object whit data
     */
    save() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_loading = true;

        // GET COUNTRIES IDS
        const id_countries = [];
        const aux_conuntries = [
          "africa",
          "asia",
          "europe",
          "north_america",
          "oceania",
          "south_america",
        ];

        aux_conuntries.forEach(continent => {
          this.formData[continent].forEach(item => {
            id_countries.push(item.id);
          });
        });

        const dataNew = {
          name: this.name,
          campusId: this.getCampus.campusId,
          countries: id_countries.join(),
          marketId: this.market.marketId,
        };

        this.$store.dispatch(UPDATE_MARKET, dataNew).then(() => {
          this.save_loading = false;

          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        });
      }
    },

    /**
     * Duplicated the market seleted
     * @method duplicate
     * @returns 'market' object whit the new data
     */
    duplicate() {
      this.duplicate_loading = true;

      const data = {
        ...this.market,
      };

      const auxCountries = [];
      data.countries.map(country => {
        auxCountries.push(country.countryId);
      });

      data.countries = auxCountries.join(",");

      data.marketId = null;
      data.name = `${data.name} copy`;

      this.$store.dispatch(STORE_MARKET, data).then(() => {
        this.duplicate_loading = false;

        this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
          variant: "success",
          solid: true,
        });

        setTimeout(() => {
          this.$router.push("/configure/markets");
        }, 2000);
      });
    },
    fetchData() {
      this.$store.dispatch(GET_MARKET, this.$route.params.id);
      const continent = lodash.groupBy(this.market.countries, "continent");
      this.name = this.market.name;
      this.editData = continent;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MARKETS.TITLE"),
        route: "/configure/markets",
      },
      { title: this.$t("GENERAL.EDIT") },
    ]);
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["market", "getCampus"]),
  },
  watch: {
    $route: "fetchData",
  },
};
</script>
